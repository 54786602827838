<template>
  <div class="user-page-content">
    <UiLoader v-model="getUsersLoader" />
    <div class="user-page-content__info" v-if="!getUsersLoader">
      <ui-loader v-model="visibleLoader" />
      <div v-if="!visibleLoader" class="user-page-content__info-header">
        <div class="header-left">
          <img :src="getUserAvatar(usersData?.avatarSrc?.fileSrc)" class="header-left__avatar" alt="">
          <div class="header-left__info">
            <div class="info-nickname">
              <h2>{{getTitle}}</h2>
              <div
                  v-if="usersData?.role !== 'Moderator' && usersData?.role !== 'Admin' && usersData?.role !== 'Active'"
                  class="info-nickname__wrap"
              >
                <p v-if="usersData?.role === 'Company' || usersData?.role === 'Government'">{{ getUserName(usersData.lastName, usersData.firstName, usersData?.middleName) }}</p>
                <!--                <span>{{ getSubTitle }}</span>-->
              </div>
            </div>
            <div class="info-roll">
              <img :src="getRole.icon" alt="">
              <p>{{ $t(getRole.text) }}</p>
            </div>
          </div>
        </div>
        <div v-if="getRole.visibleRating" class="header-right">
          <div class="header-right__rating">
            <p>{{ $store.state.userRating[usersData.starRating] }}</p>
            <UiRating size="25" v-model="usersData.starRating" />
          </div>
          <div class="header-right__count" @click="showScoreWindow" :style="{cursor: this.getUser?.role === 'Admin' || this.getUser?.role === 'Moderator' ? 'pointer' : ''}">{{ usersData.score }}</div>
        </div>
      </div>
      <div class="info-body">
        <h2 v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">{{ $t('personal-information') }}</h2>
        <div :class="['info-body__data', {active: usersData?.role === 'Active'}]">
          <div class="info-body__data-row">
            <img src="@/assets/svg/icon-location.svg" alt="">
            <div class="row-text">
              {{ usersData?.city || usersData?.region?.name }}
            </div>
          </div>
          <div class="info-body__data-row" v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && usersData?.role === 'Active' && usersData?.birthDate">
            <img src="@/assets/svg/icon-date-red.svg" alt="">
            <div class="row-text">
              {{ getFullDate(usersData?.birthDate).slice(0,10) }}
            </div>
          </div>
          <div class="info-body__data-row" v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && usersData?.phone">
            <img src="@/assets/svg/icon-phone.svg" alt="">
            <div class="row-text">
              {{ formatPhone(usersData?.phone) }}
            </div>
          </div>
          <div class="info-body__data-row" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">
            <img src="@/assets/svg/icon-mail.svg" alt="">
            <div class="row-text">
              {{ usersData.email }}
            </div>
          </div>
          <div v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && usersData?.role === 'Inspector'" class="info-body__data-row">
            <img src="@/assets/svg/icon-file.svg" alt="">
            <div class="row-text">
              {{ $t('certificate-id', {data: usersData.documentNumber || null}) }}
              <span>{{ $t('valid-until', {data: getFullDate(usersData.documentIssueDate).slice(0, 10) || null}) }}</span>
            </div>
          </div>
          <div v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && usersData?.role === 'Company'" class="info-body__data-row">
            <img src="@/assets/svg/icon-file.svg" alt="">
            <div class="row-text">
              {{ $t('taxpayer-identification-number', {data: usersData.taxNumber || null}) }}
            </div>
          </div>
          <div v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && usersData?.role === 'Lawyer'" class="info-body__data-row">
            <img src="@/assets/svg/icon-file.svg" alt="">
            <div class="row-text">
              {{ $t('diploma-id', {data: usersData.documentNumber || null}) }}
              <span>{{ $t('from', {data: getFullDate(usersData.documentIssueDate).slice(0, 10) || null}) }}</span>
            </div>
          </div>
          <div v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && (usersData?.role === 'Government' || usersData?.role === 'Company')" class="info-body__data-row">
            <img src="@/assets/svg/icon-file.svg" alt="">
            <div class="row-text">
              {{ usersData.documentNumber || null }}
              <span>{{ $t('valid-until', {data: getFullDate(usersData.documentIssueDate).slice(0, 10) || null}) }}</span>
            </div>
          </div>
        </div>
        <div class="info-body__bottom">
          <div class="info-body__bottom-documents" v-if="(getUser?.role === 'Moderator' || getUser?.role === 'Admin') && usersData?.role !== 'Active' ">
            <p>{{ $t('documents') }}</p>
            <div class="documents">
              <a
                  v-for="(item, key) in usersData.documentsSrc"
                  :key="key"
                  :href="item.fileSrc"
                  target="_blank"
                  class="document"
              >
                <img

                    src="@/assets/svg/icon-docs-grey.svg"
                    alt=""
                />
                <div class="document__item-info">
                  <p>{{ item.name || item.originalname }}</p>
                  <span>{{ getFullDate(item.createdAt)}}</span>
                </div>
              </a>
            </div>
          </div>
          <router-link
              :to="`/work-requests?user-id=${usersData?.id}&&role=${usersData?.role}`"
              v-if="!visibleLoader && (getUser?.role !== 'Company' && getUser?.role !== 'Government') && ((getUser?.role === 'Active' && usersData?.role === 'Active') || getUser?.role !== 'Active')"
          >
            <ui-button color="error">{{ $t('appeals') }}</ui-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="user-page-content__delete-account" v-if="getUser?.role === 'Admin' && !getUsersLoader">
      <p @click="visibleBlockAccountAlert = true" v-if="!usersData.isBlocked">{{ $t('block-participant') }}</p>
      <p @click="visibleUnBlockAccountAlert = true" v-else>{{ $t('unblock-this-participant') }}</p>
      <p @click="visibleDeleteAccountAlert = true">{{ $t('delete-profile') }}</p>
    </div>
    <ui-alert v-model="visibleDeleteAccountAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-want-to-delete-your-account') }}</div>
        <div class="ui-alert__text">{{ $t('alert-for-deleted-account') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="deleteAccount" color="error">{{ $t('alert-for-deleted-account') }}</ui-button>
        <ui-button @click="visibleDeleteAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
    <ui-alert v-model="visibleBlockAccountAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('alert-for-block-user') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="blockAccount(true)" color="error">{{ $t('block-participant') }}</ui-button>
        <ui-button @click="visibleBlockAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
    <ui-alert v-model="visibleUnBlockAccountAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('alert-for-unblock-user') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="blockAccount(false)" color="error">{{ $t('unblock-this-participant') }}</ui-button>
        <ui-button @click="visibleBlockAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
    <ui-alert v-model="visibleAlertScore">
      <div class="ui-alert__title">{{ $t('current-balance') }}</div>
      <div class="ui-alert__subtitle">
        <p>{{ usersData?.score}}<span>{{ $t('points') }}</span></p>
      </div>
      <div class="ui-alert__items">
        <div class="ui-alert__items-item item" v-for="(item, key) in getOrders" :key="key">
          <p class="item__date">{{ getFullDate(item.createdAt).slice(0, 10)}}</p>
          <div class="item__info">
            <div @click=" visibleAlertScore = false, $router.push(`/work-requests/${item.id}`)" class="item__info-left">{{ $t('request', {data: item.id}) }}<p>{{ $t('type-number', {data: item.type?.name}) }}</p></div>
            <p class="item__info-right">{{item.activeScore}}</p>
          </div>
        </div>
        <p class="ui-alert__items-show" @click="loadMore" v-if="visibleLoadMore">{{ $t('show-more') }}</p>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getUserRole, getUserAvatar} from "@/utils/user";
import {formatPhone, getDate, getFullDate} from "@/utils/general";

export default {
  name: "UserContent",
  components: {
    UiButton: () => import('@/components/ui/UiButton.vue'),
    UiRating: () => import('@/components/ui/UiRating'),
    UiLoader: () => import('@/components/ui/UiLoader'),
    UiAlert: () => import('@/components/ui/UiAlert.vue')
  },

  data() {
    return {
      getUserAvatar,
      getUserRole,
      formatPhone,
      visibleLoader: true,
      visibleDeleteAccountAlert: false,
      visibleBlockAccountAlert: false,
      visibleAlertScore: false,
      visibleLoadMore: true,
      visibleUnBlockAccountAlert: false,
      loader: true
    }
  },
  computed: {
    ...mapGetters(['usersData', 'getUser', 'getOrders', 'getUsersLoader']),
    getRole() {
      return getUserRole(this.usersData.role)
    },
    getTitle() {
      return this.usersData.role === 'Inspector' ? this.getUserName(this.usersData.lastName, this.usersData.firstName, this.usersData?.middleName) : '' ||
      (this.usersData.role === 'Company' || this.usersData.role === 'Government') ? this.usersData.companyName : '' ||
      this.usersData.role === 'Lawyer' ? `${ this.getUserName(this.usersData.lastName, this.usersData.firstName, this.usersData?.middleName) }` : '' ||
      (this.usersData.role === 'Moderator' || this.usersData.role === 'Admin') ? this.usersData.login : '' ||
      this.usersData.role === 'Active' ? this.usersData.login : ''
    },
    getSubTitle() {
      return this.usersData.login ? this.usersData.login : ''
    }
  },
  methods: {
    getFullDate,
    ...mapActions(['userId', 'reqGetOrders', 'editUser']),
    ...mapMutations(['setOrders', 'setUsersLoader']),

    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },

    deleteAccount() {
      this.reqDeleteAccount()
          .then(() => {
            this.visibleDeleteAccountAlert = false
          })
      console.log(111);
    },

    blockAccount(isBlocked) {
      this.setUsersLoader(true)
      this.editUser({
        acceptedOrdersCount: this.usersData.acceptedOrdersCount,
        avatar: this.usersData.avatar,
        avatarSrc: this.usersData.avatarSrc,
        birthDate: this.usersData.birthDate,
        city: this.usersData.city,
        companyName: this.usersData.companyName,
        deletedAt: this.usersData.deletedAt,
        district: this.usersData.district,
        districtId: this.usersData.districtId,
        documentFile: this.usersData.documentFile,
        documentIssueDate: getDate(this.usersData.documentIssueDate),
        documentNumber: this.usersData.documentNumber,
        documentsSrc: this.usersData.documentsSrc,
        email: this.usersData.email,
        firstName: this.usersData.firstName,
        id: this.usersData.id,
        isBlocked: isBlocked,
        lastName: this.usersData.lastName,
        login: this.usersData.login,
        middleName: this.usersData.middleName,
        mustChangePassword: this.usersData.mustChangePassword,
        phone: this.usersData.phone,
        region: this.usersData.region,
        regionId: this.usersData.regionId,
        role: this.usersData.role,
        roleRejectComment: this.usersData.roleRejectComment,
        score: this.usersData.score,
        starRating: this.usersData.starRating,
        status: this.usersData.status,
        taxNumber: this.usersData.taxNumber,
        totalOrdersCount: this.usersData.totalOrdersCount,
      }).then(() => {
        this.visibleBlockAccountAlert = false
        this.visibleUnBlockAccountAlert = false
        this.userId(this.$route.params.id).then(() => {
          this.setUsersLoader(false)
        })
      })
    },

    ad() {
      history.back()
    },

    showScoreWindow () {
      if(this.getUser?.role === 'Admin' || this.getUser?.role === 'Moderator') {
        this.reqGetOrders({
          userId: this.usersData.id,
          skip: 0,
          take: 10,
        }).then((res) => {
          this.setOrders(res.data)
          this.visibleAlertScore = !this.visibleAlertScore
          if(res.data.length !== 10) {
            this.visibleLoadMore = false
          }
        })
      }
    },

    loadMore () {
      this.reqGetOrders({
        userId: this.usersData.id,
        skip: this.getOrders.length,
        take: 10,
      }).then((res) => {
        this.setOrders(this.$store.state.ordersStore.orders.concat(res.data))
        if(res.data.length !== 10) {
          this.visibleLoadMore = false
        }
      })
    }
  },
  created() {
    this.userId(this.$route.params.id).then(() => {
      this.visibleLoader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.user-page-content {
  width: 100%;
  gap: 30px;

  &__info {
    max-width: 774px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      padding: 30px 12px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 30px;
      border-bottom: 1px solid #F5F5F5;
      margin-bottom: 30px;
      gap: 10px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      .header-left {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 992px) {
          gap: 10px;
        }

        &__avatar {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid #CE2121;

          @media (max-width: 992px) {
            width: 70px;
            height: 70px;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          gap: 15px;

          @media (max-width: 768px) {
            gap: 10px;
          }

          .info-nickname {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            @media (max-width: 768px) {
              flex-direction: column;
              align-items: start;
            }

            h2 {
              font-weight: 400;
              font-size: 20px;
              line-height: 19px;
              color: #343432;

              @media (max-width: 992px) {
                font-size: 16px;
                line-height: 15px;
              }
            }

            &__wrap {
              display: flex;
              align-items: center;
              gap: 10px;
              flex-wrap: wrap;

              p {
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 120.2%;
                color: #343432;

                &:before {
                  content: '';
                  width: 5px;
                  height: 5px;
                  background: #343432;
                  border-radius: 50%;

                  @media (max-width: 768px) {
                    display: none;
                  }
                }
              }

              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 120.2%;
                color: #343432;
              }
            }
          }

          .info-roll {
            display: flex;
            align-items: center;
            gap: 10px;

            @media (max-width: 992px) {
              gap: 6px;
            }

            img {
              width: 22px;
              height: 22px;

              @media (max-width: 992px) {
                width: 10px;
                height: 10px;
              }
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              color: #343432;

              @media (max-width: 992px) {
                font-size: 14px;
                line-height: 120.2%;
              }
            }
          }
        }
      }

      .header-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;

        @media (max-width: 768px) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 342px;
        }

        &__rating {
          display: flex;
          align-items: center;
          gap: 10px;

          @media (max-width: 768px) {
            flex-direction: row-reverse;

            ::v-deep .ui-rating {
              img {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            color: #343432;

            @media (max-width: 992px) {
              font-size: 12px;
              line-height: 11px;
            }
          }
        }

        &__count {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #E21F1F;
          box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
          border-radius: 200px;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #FFFFFF;
          padding: 14px 14px 11px;
        }
      }
    }
  }
  &__delete-account {
    display: none;
    flex-direction: column;
    gap: 10px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      cursor: pointer;
      color: #9A9A9A;
    }

    @media (max-width: 992px) {
      display: flex;
    }
  }
}

.info-body {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    color: #1B1A1F;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    max-height: 180px;
    border-bottom: 1px solid #F5F5F5;

    @media (max-width: 1200px) {
      max-height: 100%;
    }
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }

    &.active {
      max-height: 200px;
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 24px;
        height: 24px;
      }

      .row-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: #BFBDBD;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &-documents {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        color: #1B1A1F;
        font-size: 16px;
      }

      .documents {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      .document {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 768px) {
          flex-direction: column;
        }
        &__item-info {
          display: flex;
          flex-direction: column;
          gap: 5px;

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            color: #1B1A1F;
            word-break: break-all;

            @media (max-width: 768px) {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #1B1A1F;
            }
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #BFBDBD;

            @media (max-width: 768px) {
              font-weight: 400;
              font-size: 8px;
              line-height: 112.7%;
              color: #BFBDBD;
              width: 42px;
              text-align: center;
            }
          }
        }
      }
    }

    &-button {
      padding-top: 30px;
      border-top: 1px solid #F5F5F5;
    }
  }
}
::v-deep .ui-alert {
  background: #FFFFFF !important;
  padding: 40px 0 0 0 !important;
  align-items: flex-start !important;
  max-width: 406px !important;
  width: 100%;

  &__title {
    text-align: start !important;
    width: 100%;
    padding-left: 20px;
    font-size: 16px !important;
  }

  &__subtitle {
    color: #02AD21;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 112.2%;
    padding: 10px 20px;
    background-color: #F5F5F5;
    width: 100%;

    p {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: #1B1A1F;
        font-family: 'Gotham Pro Medium';
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-height: 400px;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__date {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
        margin-left: 20px;
      }

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background: #F5F5F5;

        &-left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: #1B1A1F;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          p {
            font-family: 'Gotham Pro Regular';
            font-size: 12px;
            font-weight: 400;
          }
        }

        &-right {
          color: #02AD21;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }

    &-show {
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
